import { MenuEntry } from '@encodix/egonnewuikit'

const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: 'https://egonswap.io/'
  },
  {
    label: 'Trade',
    icon: 'TradeIcon',
    initialOpenState: true,
    items: [
      {
        label: 'Exchange',
        href: '/swap'
      },
      {
        label: 'Liquidity',
        href: '/pool'
      }
    ]
  },
  {
    label: 'Farms',
    icon: 'FarmIcon',
    href: 'https://egonswap.io/#/farms'
  },
  {
    label: 'Pools',
    icon: 'PoolIcon',
    href: 'https://egonswap.io/#/pools'
  },
  // {
  //   label: 'Leveraged farming',
  //   icon: 'PoolIcon',
  //   href: 'http://egonswap.io/comingsoon',
  // },
  {
    label: 'LaunchPAD',
    icon: 'IfoIcon',
    href: 'https://egonswap.io/#/comingsoon',
  },
  // {
  //   label: 'Lottery',
  //   icon: 'TicketIcon',
  //   href: 'http://egonswap.io/#/comingsoon'
  // },
  // {
  //   label: 'Referral',
  //   icon: 'GroupsIcon',
  //   href: 'http://egonswap.io/#/referral',
  // },
  {
    label: 'Info Analytics ',
    icon: 'InfoIcon',
    href: 'https://info.egonswap.io',
  },
  {
    label: 'Contact Us',
    icon: 'GroupsIcon',
    href: 'https://egonswap.io/#/contact',
  },
  // {
  //   label: 'Price Charts',
  //   icon: 'InfoIcon',
  //   items: [
  //     {
  //       label: 'DexGuru',
  //       href: 'https://dex.guru/',
  //     },
  //     {
  //       label: 'PooCoin',
  //       href: 'https://poocoin.app/',
  //     },
  //     {
  //       label: 'BoggedFinance',
  //       href: 'https://charts.bogged.finance/',
  //     },
  //     {
  //       label: 'DexTools',
  //       href: 'https://www.dextools.io/',
  //     },
  //   ],
  // },
  {
    label: 'Audit Report',
    icon: 'AuditIcon',
    items: [
      {
        label: 'TechRate',
        href: 'https://github.com/TechRate/Smart-Contract-Audits/blob/main/December/EgonSwap.pdf',
      },
      {
        label: 'Cyber Security',
        href: 'https://github.com/JorgeRodriguezsec/CTDsec/blob/main/Audits/Cybersecurity_Audit_CTDSEC_EGONSWAP.pdf',
      },
      {
        label: 'Certik',
        href: 'https://egonswap.io/#/comingsoon',
      },
      // {
      //   label: 'DappRadar',
      //   href: 'https://dappradar.com/',
      // },
      // {
      //   label: 'CoinGecko',
      //   href: 'https://www.coingecko.com/en/',
      // },
      // {
      //   label: 'LiveCoinWatch',
      //   href: 'https://www.livecoinwatch.com/',
      // },
      // {
      //   label: 'Vfat',
      //   href: 'https://vfat.tools/',
      // },
    ],
  },
  {
    label: 'Listings',
    icon: 'LayerIcon',
    items: [
      {
        label: 'BscScan',
        href: 'https://bscscan.com/address/0x05995a068bdac17c582eC75AB46bb8e7394be1d9',
      },
      // {
      //   label: 'DappRadar',
      //   href: 'https://dappradar.com/',
      // },
      // {
      //   label: 'CoinGecko',
      //   href: 'https://www.coingecko.com/en/',
      // },
      // {
      //   label: 'LiveCoinWatch',
      //   href: 'https://www.livecoinwatch.com/',
      // },
      // {
      //   label: 'Vfat',
      //   href: 'https://vfat.tools/',
      // },
    ],
  },
  {
    label: 'More',
    icon: 'MoreIcon',
    items: [
      {
        label: 'Github',
        href: 'https://github.com/egonswap/',
      },
      {
        label: 'Docs',
        href: 'https://docs.egonswap.io/',
      },
      {
        label: 'Telegram',
        href: 'https://t.me/EagleNetWorkApp',
      },
      // {
      //   label: 'Blog',
      //   href: 'https://egonswap.medium.com/',
      // },
      // {
      //   label: 'Voting',
      //   href: 'http://egonswap.io/comingsoon',
      // },
    ],
  },
  // {
  //   label: 'Partnerships/IFO',
  //   icon: 'GooseIcon',
  //   href: 'https://docs.google.com/forms/d/e/1FAIpQLSe7ycrw8Dq4C5Vjc9WNlRtTxEhFDB1Ny6jlAByZ2Y6qBo7SKg/viewform?usp=sf_link',
  // },
  // {
  //   label: 'Audit by Hacken',
  //   icon: 'AuditIcon',
  //   href: 'https://www.egonswap.io/files/hackenAudit.pdf',
  // },
  // {
  //   label: 'Audit by CertiK',
  //   icon: 'AuditIcon',
  //   href: 'https://certik.org/projects/goose-finance',
  // },
]

export default config
